import {IMapState} from "../models/IMapState";
import {ls_getObjectsColorsOnMap} from "../helpers/localStorage";

export const NO_INFO = 'н/д'

export const graveFieldNames = {
  'lastname': 'Фамилия',
  'firstname': 'Имя',
  'patronymic': 'Отчество',
  'dob': 'Дата рождения',
  'dod': 'Дата Смерти',
  'burialType': 'Способ погребения',
  'casketType': 'Тип погребения'
} as any

export enum algorithmsTitles {
  CopySchema = 'Копирование схемы',
  SearchPlacesSections = 'Поиск секторов оград',
  CheckSequencesInDb = 'Проверка ошибок в базе данных',
  CalculateDimensions = 'Автоматический расчёт размеров оград кладбища',
  CheckGravesCoordinates = 'Проверка координат могил кладбища',
  DeleteEmptyGraves = 'Удаление могил без данных',
  DeletePlacesWithoutGeometryAndPhotos = 'Удаление оград без могил, фотографий и геометрии',
  DeleteTemporaryObjects = 'Удаление объектов с временными ргис id',
  FixDates = 'Исправление неправильного отображения дат из бд',
  MovePhotosFromKz = 'Перенос фотографий из кз',
  CalculatePlacesTypes = 'Калькулятор типов оград',
  CalculatePlacesTypesByAreaAndDates = 'Калькулятор типов оград по площади и минимальной дате',
  StubsFinding = 'Поиск заглушек',
  FindObjectsWithoutGeometry = 'Поиск могил/оград без координат',
  SearchDifferenceBetweenRgis = 'Поиск не совпадений с РГИС данными',
  SyncPhotos = 'Автоматический поиск фото',
  FindGravesDuplicates = 'Поиск дубликатов могил',
  DeletePhotosDuplicates = 'Удаление дублированных фотографий',
  FindNotExistingPhotos = 'Поиск не выгруженных фотографий',
  SyncSchemaRgisWithResponsible = 'Ргис совмещение с данными ответственных',
  SyncSchemaRgisWithoutResponsible = 'Ргис совмещение без данных ответственных',
  SyncSchema_book = 'Совмещение с книгами',
  SyncSchema_urnBook = 'Совмещение с книгами урн с прахом',
  ReplaceSection = 'Заменить сектор',
  ReplaceBorder = 'Заменить границу',
  ReplacePlaces = 'Заменить координаты оград',
  DistributePhotosOnCroc = 'Распределить фото из временной папки на облаке',
  FixFio = 'Исправить CapsLock в ФИО',
  RotateToAlbum = 'Повернуть фото оград в альбомную орентацию',
  RotateToBook = 'Повернуть фото могил в книжную орентацию',
}

export const initialMapState: IMapState = {
  center: [] as number[],
  schema: undefined as string | undefined,
  zoom: 17 as number,
  selectedObject: null as any | null,
  preSelectedObject: null as null | {
    id: number,
    type: 'planned' | 'common' | 'note' | 'task' | 'placeWithoutSection' | 'section' | 'border' | 'grave'
  },
  selectedSectionsNumbers: [] as string[],
  preSelectedSectionNumber: undefined as string | undefined,
  createdObject: null as any | null,
  needReFetch: false,
  isOnRulers: false,
  editGeometryMode: false,
  ruler: {
    isOn: false,
    pointsArray: [] as any[]
  },
  rowsRuler: {
    isOn: false,
    pointsArray: [] as any[]
  },
  selectFinger: {
    isOn: false,
    sectionsArray: [] as any[],
    placesArray: [] as any[]
  },
  objectsColors: {
    place: 'rgb(20,128,236)',
    graveFill: 'rgb(122,48,72)',
    graveColor: 'rgb(255,221,0)',
    selected: 'rgb(31,203,34)',
  }
}

export const getInitialMapState = (): IMapState => {
  const objectsColorsFromLS = ls_getObjectsColorsOnMap()

  if (objectsColorsFromLS) {
    return {...initialMapState, objectsColors: {...initialMapState.objectsColors, ...objectsColorsFromLS}};
  }

  return initialMapState;
}