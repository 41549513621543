import React, {FC} from 'react';
import styles from './mapLayoutModal.module.scss'
import Icon from "../../../../components/UI/Icons/Icon";
import {Link} from "react-router-dom";
import {GRAVE_PAGE, PLACE_PAGE} from "../../../../router/routeConsts";
import {Stack} from "@mui/material";
import {NO_INFO} from "../../../../utils/utils";
import Loader from "../../../../components/UI/Loader/Loader";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../store/mapStore";
import {ls_getProjectInfo} from "../../../../helpers/localStorage";

interface Props {
  onClose: () => void,
  onCopy: (textToCopy: string) => Promise<void>,
  smallSize?: boolean
}

const PlaceInfoModal: FC<Props> = ({onClose, onCopy, smallSize = false}) => {
  const [mapState] = useRecoilState(mapStateAtom)
  const projectInfo = ls_getProjectInfo()

  if (!mapState.preSelectedObject || mapState.preSelectedObject?.type === 'section' || mapState.preSelectedObject?.type === 'border' || mapState.preSelectedObject?.notShowInfo) {
    return <></>
  }

  return (
    <>
      <div className={styles.modal} style={{bottom: smallSize ? undefined : "4rem", height: smallSize ? "180px" : undefined}}>
        <div className={styles.close} onClick={onClose}>
          <Icon icon={"close"}/>
        </div>

        <p>Ограда {mapState.preSelectedObject?.type === "placeWithoutSection" ? '(без ссылки на сектор)' : ''}</p>

        {mapState.selectedObject ?
          <>
            <Link
              to={PLACE_PAGE + mapState.selectedObject.id + `?${mapState.preSelectedObject?.type === 'planned' ? 'planned' : 'common'}`}>
              <span>№</span>
              <span>{mapState.selectedObject.placeNumber}</span>
            </Link>

            {mapState.selectedObject.photos?.length && !smallSize ?
              <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Stack width={"140px"}>
                  <img src={`${projectInfo.hostingUrl + projectInfo.imageFolder}/` + mapState.selectedObject.photos[0]}
                       alt={mapState.selectedObject.photos[0]}/>
                </Stack>
              </Stack>
              : <></>
            }


            <p>Могилы</p>

            <Stack spacing={1}>
              {mapState.selectedObject.graves?.map((grave: any) =>
                <div style={{display: 'flex'}} key={grave.uuid}>
                  <Link to={GRAVE_PAGE + grave.id}>
                    <span>№</span>
                    <span>{grave.monumentNumber}</span>
                    <span>{grave.lastname || NO_INFO}</span>
                    <span>{grave.firstname || NO_INFO}</span>
                    <span>{grave.patronymic || NO_INFO}</span>
                  </Link>

                  <div
                    className={styles.copy_btn_wrapper}
                    onClick={
                      () => onCopy(`${grave.lastname || NO_INFO} ${grave.firstname || NO_INFO} ${grave.patronymic || NO_INFO}`)
                    }
                  >
                    <Icon icon={"copy"}/>
                  </div>
                </div>
              )}
            </Stack>
          </>
          :
          <Loader/>
        }
      </div>
    </>
  )
};

export default PlaceInfoModal;