import React, {useState} from 'react';
import Icon from "../../UI/Icons/Icon";
import ChangeObjectsColorsDialog from "./ChangeObjectsColorsDialog";

const ChangeObjectColors = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Icon icon={"settings"} onClick={toggleOpen}/>

      <ChangeObjectsColorsDialog isOpen={isOpen} onClose={toggleOpen}/>
    </>
  );
};

export default ChangeObjectColors;