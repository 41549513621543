import React, {FC} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {useForm} from "react-hook-form";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../store/mapStore";
import {RgbStringColorPicker} from "react-colorful";
import {ls_setObjectsColorsOnMap} from "../../../helpers/localStorage";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface IForm {
  place: string;
  graveFill: string;
  graveColor: string;
  selected: string;
}

const ChangeObjectsColorsDialog: FC<Props> = ({isOpen, onClose}) => {
  const [{objectsColors}, setMapState] = useRecoilState(mapStateAtom);

  const {handleSubmit, reset, setValue, getValues} = useForm({
    defaultValues: {
      place: objectsColors?.place ?? 'rgb(20,128,236)',
      graveFill: objectsColors?.graveFill ?? 'rgb(122,48,72)',
      graveColor: objectsColors?.graveColor ?? 'rgb(255,221,0)',
      selected: objectsColors?.selected ?? 'rgb(31,203,34)',
    }
  })

  const onSubmit = (values: IForm) => {
    setMapState((prev) => ({...prev, objectsColors: values as unknown as Record<string, string>}));
    ls_setObjectsColorsOnMap(values as unknown as Record<string, string>)
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Настройка цветов объектов на карте
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} padding={1}>
          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <p>Цвет ограды</p>

            <RgbStringColorPicker
              onChange={(color) => setValue("place", color)}
              color={getValues("place")}
            />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <p>Цвет заливки могилы</p>

            <RgbStringColorPicker
              onChange={(color) => setValue("graveFill", color)}
              color={getValues("graveFill")}
            />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <p>Цвет контура могилы</p>

            <RgbStringColorPicker
              onChange={(color) => setValue("graveColor", color)}
              color={getValues("graveColor")}
            />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <p>Цвет выбранного объекта</p>

            <RgbStringColorPicker
              onChange={(color) => setValue("selected", color)}
              color={getValues("selected")}
            />
          </Stack>

        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton onClick={onClose} autoFocus>
          Отмена
        </LoadingButton>
        <LoadingButton onClick={handleSubmit(onSubmit)}>
          Обновить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeObjectsColorsDialog;