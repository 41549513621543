import { ITokens } from '../models/ITokens';

export const ACCESS_TOKEN_KEY = '$r2d2o2'
export const REFRESH_TOKEN_KEY = '$s3pio'
export const PROJECT_INFO_KEY = '$pi'
export const OBJECTS_COLORS_ON_MAP_KEY = '$o2c3o4m'
export const CURRENT_PAGE_DECODE = '$p'
export const RGIS_JOB_ID_KEY = '$rji'
export const BOOK_JOB_ID_KEY = '$bji'

export type ProjectInfo = {
  id: number
  schema: string
  imageFolder: string
  hostingUrl: string
  name: string
}


export const ls_clear = () => {
  localStorage.clear()
}

export const ls_setCurrentPageDecode = (page: number) => {
  localStorage.setItem(CURRENT_PAGE_DECODE, page.toString())
}

export const ls_setTokens = (tokens: ITokens) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, tokens.access_token)
  localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token)
}

export const ls_setObjectsColorsOnMap = (colors: Record<string, string>) => {
  localStorage.setItem(OBJECTS_COLORS_ON_MAP_KEY, JSON.stringify(colors))
}

export const ls_setProjectInfo = (project: ProjectInfo | undefined) => {
  if(!project) {
    localStorage.removeItem(PROJECT_INFO_KEY)
    return
  }

  localStorage.setItem(PROJECT_INFO_KEY, JSON.stringify({
    id: project.id,
    schema: project.schema,
    imageFolder: project.imageFolder,
    hostingUrl: project.hostingUrl,
    name: project.name,
  }))
}

export const ls_setCurrentJobId = (jobId: number | null, currentKey: 'rgis' | 'book') => {
  const key = currentKey === "rgis" ? RGIS_JOB_ID_KEY : BOOK_JOB_ID_KEY
  localStorage.setItem(key, jobId ? jobId.toString() : '')
}

export const ls_getCurrentJobId = (currentKey: 'rgis' | 'book') => {
  const key = currentKey === "rgis" ? RGIS_JOB_ID_KEY : BOOK_JOB_ID_KEY
  const jobId = localStorage.getItem(key)

  return jobId ? Number(jobId) : null
}

export const ls_getTokens = () => {
  return {
    access: localStorage.getItem(ACCESS_TOKEN_KEY),
    refresh: localStorage.getItem(REFRESH_TOKEN_KEY)
  }
}

export const ls_getCurrentPageDecode = () => {
  const page = localStorage.getItem(CURRENT_PAGE_DECODE)

  if (page && !isNaN(Number(page))) {
    return Number(page)
  }

  return 1
}

export const ls_getProjectInfo = (): ProjectInfo => {
  const projectInfo = localStorage.getItem(PROJECT_INFO_KEY) as string

  return JSON.parse(projectInfo);
}

export const ls_getObjectsColorsOnMap = (): Record<string, string> => {
  const objectsColors = localStorage.getItem(OBJECTS_COLORS_ON_MAP_KEY) as string

  return JSON.parse(objectsColors);
}

