import React from 'react';
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../store/mapStore";
import cn from "classnames";
import styles from "../customButtons.module.scss";
import Icon from "../../../UI/Icons/Icon";

const EditGeometryModeButton = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)

  const onClick = () => {
    setMapState((prev) => ({...prev, editGeometryMode: !prev.editGeometryMode}));
  }

  return (
    <div className={cn('leaflet-top', 'leaflet-left', styles.edit_geometry_mode_btn)}>
      <div onClick={onClick}
           className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)}>
        <Icon icon={"edit"} className={mapState.editGeometryMode ? styles.enabled_btn : undefined}/>
      </div>
    </div>
  );
};

export default EditGeometryModeButton;